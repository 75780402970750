.breadcrumb{
    padding: 8px 8px 8px 49px;
    width: fit-content;
    background-color: #ffffff;
    border-radius: 0px 10px 10px 0px;
    margin-left: -16px;
}
.home-welcome-section{
    padding: 30px;
    overflow: auto;
    width: 85%;
}
.home-welcome-section::-webkit-scrollbar{
    width: 0;
    height: 0;
}
.home-welcome-section h1{
    font-weight: 600;
    font-size: 36px;
    line-height: 24px;
    color: #707081;
}
.home-welcome-section h4{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #707081;
    padding-top: 20px;
    padding-bottom: 10px;
}
.cards-home{
    display: flex;
    gap: 20px;
}
.card-quick-acess{
    width: 272px;
    height: 236px;
    background-color: #ffffff;
    border-radius: 10px;
    text-align: center;
    padding: 16px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.card-quick-acess h5{
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-transform: capitalize;
    color: #474D66;
    padding-top: 12px;
    margin-bottom: 0px;
}
.card-quick-acess p{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #707081;
    padding-top: 12px;
    margin-bottom: 0px;
}
.card-quick-acess:hover{
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.16);
    border-radius: 10px;
}
.card-quick-acess:hover img{
    transform: rotate(7.7deg);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.image-bakground {
    width: 123px;
    background: #FAFBFF;
    height: 123px;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

@primary-color: #4BB354;