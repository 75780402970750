.finacial-report-container {
  padding: 16px;
  height: inherit;
  display: flex;
  background: #fafbff;
}
.report-preview-heading {
  border-bottom: 0.5px solid #e6e8f0;
  height: 63.5px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #4a4a4a;
  margin-bottom: 19.5px;
}

.saved-cards-heading {
  font-size: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #262626;
}

.saved-expandable-group-container {
  height: 34px;
  width: 100%;
  padding: 0px 12px;
  border-bottom: 1px solid #e6e8f0;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #fff;

  .saved-expandable-group-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    color: #474d66;
    flex: 1 1 auto;
  }
}

.saved-draft-item-container {
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #fafbff;
  border-bottom: 1px solid #e6e8f0;
  padding: 0px 37.5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;

  svg {
    fill: #c1c4d6;
  }

  .saved-draft-item-menu {
    display: none;
    svg {
      fill: #75cf7c;
      width: 30px;
      height: 20px;
    }
  }

  &:hover {
    background: #eef8f4;
    color: #3d8e44;
    svg {
      fill: #3d8e44;
    }

    .saved-draft-item-menu {
      display: block;
    }
  }
}
.finacial-report-container.financial-height-setter .left-part {
  overflow-y: auto;
  height: 100%;
  min-height: 150px;
}

.finacial-report-search-container {
  .ant-input-group-addon {
    display: none;
  }
}
.loader-spin {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.report-template-item {
  background-color: #fafbff;
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;

  .report-template-item-menu {
    svg {
      // fill: #75cf7c;
      width: 30px;
      height: 20px;
    }
  }

  &:hover {
    background: #eef8f4;
    color: #3d8e44;
    svg {
      fill: #3d8e44;
    }

    .sreport-template-item-menu {
      display: block;
    }
  }
}

@primary-color: #4BB354;