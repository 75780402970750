.sidebar-container {
  width: 300px;
  min-width: 300px;
  background: #ffffff;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.05);
  padding: 33px 38px 48px 38px;
  transition: width 200ms;
  z-index: 1;

  .sidebar-search-container {
    margin-top: 44px;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none !important;
  }

  .ant-menu-submenu-title {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .ant-menu-submenu-arrow {
    right: 0px;
  }

  .ant-menu-sub.ant-menu-inline {
    padding: 0;
    background: #fff;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
}

.sidebar-menu-item-selected {
  color: #4bb354;
  svg {
    path {
      fill: #4bb354;
    }
  }
}

@primary-color: #4BB354;