.right-comp2 {
  // width: 656px;
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;

  .section-header {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 0.5px rgba(230, 232, 240, 1);
    height: 63px;
    min-height: 63px;

    & > h2 {
      margin: unset;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }

    .header-right {
      display: flex;

      & > button {
        margin-left: 19px;
        cursor: pointer;
      }

      & > :nth-child(1),
      > :nth-child(2) {
        border: solid 1px rgba(216, 218, 229, 1);
        display: flex;
        align-items: center;
        padding: 6px 16px;
        border-radius: 4px;
        background-color: #fff;

        & > img {
          margin-right: 8px;
        }

        & > span {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: center;
          color: rgba(105, 111, 140, 1);
        }
      }
    }
  }

  .section-body {
    padding: 22px 0px 35px 0px;
    overflow: auto;
    flex: 1 1;

    .file-attributes {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .each-attribute {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        color: rgba(112, 112, 129, 1);
      }
    }
  }
}

.table-balance {
  display: flex;
  flex-direction: column;

  .table-header {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px rgba(236, 239, 249, 1);

    .header-cell {
      text-transform: uppercase;
      padding-left: 16px;
      background-color: rgb(250, 251, 255);
      padding-top: 0px;
      padding-bottom: 0px;
      height: 40px;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  .table-body {
    flex: 1 1 auto;

    .table-each-row {
      display: flex;
      flex-direction: row;
      //   justify-content: space-between;
      border-bottom: solid 1.5px rgba(236, 239, 249, 1);

      .data-cell {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        padding-left: 16px;

        input {
          accent-color: rgba(117, 207, 124, 1);
        }
      }

      .is-disabled {
        background-color: rgba(236, 239, 249, 1);
        color: rgba(193, 196, 214, 1);
      }
    }
  }

  .header-bg {
    background-color: rgba(250, 251, 255, 1) !important;
  }

  .disabled-text {
    color: rgba(193, 196, 214, 1);
  }
}

.more-accurate-value {
  height: 24px;
  background: #4bb354;
  border: 1px solid #4bb354;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.less-accurate-value {
  height: 24px;
  background: #e5f2e4;

  border: 1px solid #bcdcbf;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  color: #707081;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-defined-value {
  height: 24px;
  background: #fff;

  border: 1px solid #d8dae5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0px 8px;

  font-size: 12px;
  line-height: 16px;

  color: #4a4a4a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.component-drop-customised{
  border: none !important;
  color: #4a4a4a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 16px;
  height: 26px;
  display: flex;
  align-items: center;
}
.component-drop-customised:focus-visible,.component-drop-customised .ant-select-selector:focus-visible{
  outline: none;
}
.component-drop-customised .ant-select-selector{
  background-color: transparent !important;
  height: 24px!important;
  align-items: center;
}
.component-drop-customised .ant-select-selection-search{
  background: #fff;
  border: 1px solid #d8dae5;
  border-radius: 4px;
  height: 24px;
  left:0px !important;
  right:20px !important
}
.component-drop-customised .ant-select-selection-search input{
  height: 24px !important;
  accent-color: none !important
}
.component-drop-customised .ant-select-selection-placeholder{
  height: 24px;
  padding: 0px 8px;
  display: block;
}
.component-drop-customised .ant-select-arrow{
  right: 0px !important;
}



.disabled-value {
  height: 24px;
  background: #f4f6fa;
  /* N400-Border */

  color: #c1c4d6;
  border: 1px solid #d8dae5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  font-size: 12px;
  line-height: 16px;

  color: #c1c4d6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@primary-color: #4BB354;