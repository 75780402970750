.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  display: flex;
  font-size: 0;
}

.ant-radio-button-wrapper {
  text-align: center;
  flex: 1 1 auto;
  color: #696f8c;
  font-size: 12px;
  border: 1px solid #d8dae5;
}

.ant-radio-button-checked {
  background-color: #eef8f4;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}

@primary-color: #4BB354;