.right-comp {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  .section-header {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 0.5px rgba(230, 232, 240, 1);
    height: 63px;
    min-height: 63px;

    & > h2 {
      margin: unset;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  .section-body {
    padding: 22px 28px 35px 35px;
    overflow: auto;

    .file-attributes {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .each-attribute {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        color: rgba(112, 112, 129, 1);
      }
    }

    .disregard-row {
      display: flex;
      align-items: center;
      margin: 22px 0;

      & > span {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
      }

      .ant-input-number {
        margin: 0 8px;
        width: 40px;

        .ant-input-number-handler-wrap {
          display: none;
        }
      }
    }

    .records-count {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 16px;
    }
  }
}

.table-common {
  display: flex;
  flex-direction: column;

  .table-header {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px rgba(236, 239, 249, 1);
    background-color: rgb(250, 251, 255);

    .header-cell {
      text-transform: uppercase;
      padding-left: 16px;

      padding-top: 12px;
      padding-bottom: 12px;
      // height: 40px;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  .table-body {
    flex: 1 1 auto;

    .table-each-row {
      display: flex;
      flex-direction: row;
      //   justify-content: space-between;
      border-bottom: solid 1.5px rgba(236, 239, 249, 1);

      .data-cell {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
      }

      .is-disabled {
        background-color: rgba(236, 239, 249, 1);
        color: rgba(193, 196, 214, 1);
      }
    }
  }

  .header-bg {
    background-color: rgba(250, 251, 255, 1) !important;
  }

  .disabled-text {
    color: rgba(193, 196, 214, 1);
  }
}
main.section-body.table-height-scrollbar .table-common{
  height: calc(100vh - 458px);
  min-height: 100px;
  overflow: auto;
}
main.section-body.table-height-scrollbar .table-common::-webkit-scrollbar{
  background: transparent; 
  height: 10px;
  width: 10px;
}
main.section-body.table-height-scrollbar .table-common::-webkit-scrollbar-thumb{
  background:#d9d9d9;
  border-radius: 10px;
}
main.section-body.table-height-scrollbar .table-each-row.table-header{
  position: sticky;
  top: 0;
}
@primary-color: #4BB354;