.TutorialVideos {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
}
.TutorialVideos iframe {
  width: 100%;
}
.tutorial-video-titles h4 {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #262626;
  padding-top: 13px;
  padding-bottom: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tutorial-video-titles p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #707081;
  padding-top: 8px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.training-screen {
  position: relative;
  cursor: pointer;
}
.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 54px;
  height: 54px;
  background: rgba(74, 74, 74, 0.3);
  box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.video-popup-iframe iframe {
  max-width: 900px;
  width: 100%;
  min-height: 500px;
  height: 100%;
  margin-top: 15px;
}

@primary-color: #4BB354;