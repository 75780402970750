.make-adjustment-journal-date-container {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4a4a4a;
  margin-bottom: 8px;
}
.equation-btn {
  border: solid 1px #d8dae5;
  display: inline-block;
  width: 100%;
  align-items: center;
  text-align: left !important;
  padding: 5px 16px;
  border-radius: 4px;
  background-color: #fff !important;
}
.minus-square {
  margin-right: 10px;
  color: #ff0000;
}
.goto-modal {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  width: 100%;
  // flex-direction: row-reverse;
  // justify-content: space-around;
}
.components-text-fields input,
.components-text-fields textarea {
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 7px;
}
.components-text-fields textarea {
  height: 155px;
}
.equation-modal-height .ant-select-selector {
  background-color: #fff !important;
}
.title-created-equation {
  margin: 20px 0 10px;
}
.equations-added-height {
  height: 160px;
  overflow: auto;
}
.selected-equation-list-style {
  display: flex;
  width: 100%;
  align-items: center;
}
.selected-equation-items {
  background-color: #f7f7f7;
  padding: 15px;
  margin-bottom: 8px;
  border-radius: 7px;
  width: 100%;
}

.operator-container {
  background: #ffffff;
  border: 1px solid #d8dae5;
  border-radius: 4px;
  height: 28px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8f95b2;
  font-size: 16px;
  margin-right: 6px;
  cursor: pointer;

  &:hover {
    border: 1px solid #4bb354;
    color: #4bb354;
  }
}

.add-equation-button {
  display: flex;
  align-items: center;
  margin-left: 30px;
  cursor: pointer;
  color: #4bb354;
  font-weight: 500;
}
.create-equation-checkbox span {
  font-size: 13px;
  font-size: 13px !important;
  font-weight: 400 !important;
}

@primary-color: #4BB354;