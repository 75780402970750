/* Basic editor styles */
.ProseMirror {
  font-family: "Times New Roman", Times, serif;
  font-weight: 400;
  font-size: 14px;
  > * + * {
    margin-top: 0.75em;
  }

  p {
    margin: 12px 0;
    line-height: normal;
  }

  ul,
  ol {
    padding: 0 1.2rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
  // table {
  //   border-collapse: collapse;
  //   margin: 0;
  //   overflow: hidden;
  //   table-layout: fixed;
  //   width: auto !important;
  //   border-radius: 5px;
  //   display: block;
  //   max-width: -moz-fit-content;
  //   // max-width: fit-content;
  //   overflow-x: auto;
  //   white-space: nowrap;
  //   min-width: unset !important;
  // }

  // th {
  //   font-weight: unset;
  // }

  // td,
  // th {
  //   border: 1px solid #aaa;
  //   // box-sizing: border-box;
  //   // min-width: 200px;
  //   padding: 9px 22px;
  //   position: relative;
  //   // vertical-align: top;
  //   text-align: left;
  // }

  table {
    // // width: 100% !important;
    // height: 100%;
    // position: relative;
    // display: inline-block;
    // table-layout: fixed;
    // border-collapse: collapse;
    // margin: 0;
    // overflow: hidden;
    // table-layout: fixed;
    // width: 100%;
    width: 100%;
    min-width: 100%;

    td,
    th {
      border: 1px solid #aaa;
      box-sizing: border-box;
      min-width: 1px;
      padding: 5px 5px;
      position: relative;
      vertical-align: top;
      text-align: unset;
      font-weight: normal;

      > * {
        margin-bottom: 0;
      }
    }

    .selectedCell:after {
      background: rgba(200, 200, 255, 0.4);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #adf;
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px;
    }

    p {
      margin: 0;
      // margin-block-start: 0px !important;
      // margin-block-end: 0px !important;
    }
  }
}

.ProseMirror-focused {
  outline: none;
}

.editor-container {
  background: #ffffff;
  border: 1px solid #d8dae5;
  border-radius: 4px;
  padding: 10px;
}

.editor-menu-container {
  border-bottom: 0.5px solid #e6e8f0;
  margin-bottom: 25px;
  padding-bottom: 15px;
}

.editor-menu-item-container {
  background-color: transparent;
  border: none !important;
  cursor: pointer;
  margin-right: 5px;
  font-size: 16px;
  color: #8f95b2;
  margin-bottom: 5px;
}

.Prosemirror .selectedCell:after {
  background: rgba(200, 200, 255, 0.4);
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.Prosemirror .column-resize-handle {
  background-color: #adf;
  bottom: -2px;
  position: absolute;
  right: -2px;
  pointer-events: none;
  top: 0;
  width: 4px;
}

// table p {
//   margin: 0;
// }

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

[data-rmiz-wrap="visible"],
[data-rmiz-wrap="hidden"] {
  position: unset;
  display: unset;
  align-items: unset;
}

.chapter-link-container:hover {
  border: 1px solid #eee !important;
}

.table-options {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 100;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.table-options-section {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.table-options-button {
  margin-right: 5px;
  margin-top: 5px;
}

table::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
table::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
table::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 5px;
}

/* Handle on hover */
table::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.editor-container--full {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  z-index: 100;
  .editor-menu-container {
    height: 15vh;
    padding: 16px;
    box-sizing: border-box;
  }
  .editor-content {
    height: 85vh !important;
    padding: 16px;
    box-sizing: border-box;
    overflow-y: scroll;
  }
}

u {
  // padding-bottom: 10px;
  border-bottom: 1px solid;
  text-decoration: none;
}

du {
  // padding-bottom: 10px;
  border-bottom: 4px double;
  text-decoration: none;
}

.equationNode {
  background-color: #d5eee0;
}
.page-break {
  border-bottom: 1px solid #ddd;
}

.dotted-line {
  border: 1px solid;
}

.section-editor-label {
  background-color: #d6e0ff;
  color: #2952cc;
  padding: 2px 6px;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.multi-instance-section-container {
  height: calc(100vh - 488px);

  overflow-y: auto;
}

// .ProseMirror {
//   table {
//     border-collapse: collapse;
//     margin: 0;
//     overflow: hidden;
//     table-layout: fixed;
//     width: 100%;

//     td,
//     th {
//       border: 2px solid #ced4da;
//       box-sizing: border-box;
//       min-width: 1em;
//       padding: 3px 5px;
//       position: relative;
//       vertical-align: top;

//       > * {
//         margin-bottom: 0;
//       }
//     }

//     th {
//       text-align: right;
//     }

//     .selectedCell:after {
//       background: rgba(200, 200, 255, 0.4);
//       content: "";
//       left: 0;
//       right: 0;
//       top: 0;
//       bottom: 0;
//       pointer-events: none;
//       position: absolute;
//       z-index: 2;
//     }

//     .column-resize-handle {
//       background-color: #adf;
//       bottom: -2px;
//       position: absolute;
//       right: -2px;
//       pointer-events: none;
//       top: 0;
//       width: 4px;
//     }

//     p {
//       margin: 0;
//     }
//   }
// }

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.command-list-item-container {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  // font-weight: 500;
  font-size: 14px;
  // line-height: 1;
  width: 100%;
  cursor: pointer;
  border: none;
  // opacity: 1;
  color: rgb(0, 0, 0);
  outline: none;
  padding: 0px 21px;
  height: 40px;
}

#tippy-1 {
  background-color: none;
  border-radius: 0px;
  width: auto;
  height: auto;
  overflow-y: scroll;
  box-shadow: none;
}

div[data-tippy-root] {
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  max-height: 400px;
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.08) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 2px 4px;
}

div[data-tippy-root] ul {
  list-style: none !important;
  text-align: left !important;
  height: 100% !important;
  padding: 15px 0px !important;
  margin: 0px !important;
}

div[data-tippy-root] ul li {
  padding: 0px !important;
  margin: 0px !important;
}

.slash-commands-group-container {
  color: rgba(55, 53, 47, 0.65);
  font-size: 12px;
  font-weight: 500;
  padding-left: 20px;
  margin-bottom: 10px;
}

@primary-color: #4BB354;