.popup-item-container {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 16px;
  cursor: pointer;
  svg {
    fill: #8f95b2;
    width: 15px;
    height: 15px;
    path {
      fill: #8f95b2;
    }
  }

  &:hover {
    background: #eef8f4;
    color: #4bb354;
    svg {
      fill: #4bb354;
      path {
        fill: #4bb354;
      }
    }
  }
}

.popup-item-selected-container {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 16px;
  cursor: pointer;
  background: #eef8f4;
  color: #4bb354;
  svg {
    width: 15px;
    height: 15px;
    path {
      fill: #4bb354;
    }
  }
}

.popup-delete-item-container {
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
  padding: 0px 16px;
  svg {
    width: 15px;
    height: 15px;
    fill: #8f95b2;
  }

  &:hover {
    background: #f9dada;
    color: #ff4d4f;
    svg {
      fill: #ff4d4f;
    }
  }
}

.popup-disabled-item-container {
  display: flex;
  align-items: center;
  height: 40px;
  cursor: not-allowed;
  padding: 0px 16px;
  color: #d9d9d9fc;
  font-size: 12px;

  &:hover {
    background: #fafbff;
    color: #d9d9d9fc;
  }
}

@primary-color: #4BB354;