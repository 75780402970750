.what-cards-design{
    width: 281px;
    background: #F4F6FA;
    border-radius: 9px;
    padding: 15px 18px;
}
.what-card-slider{
    background: #ffffff;
    border-radius: 10px;
    padding: 46px 28px;
    position: relative;
}
.slider-btns {
    display: flex;
    gap: 20px;
    position: absolute;
    z-index: 20;
    top: 23px;
    right: 28px;
}
.slick-slide > div {
    margin: 0 10px;
}
.what-date span{
    width: 5px;
    height: 18px;
    background: #20BC7B;
    border-radius: 3px;
    display: block;
}
.what-date{
    display: flex;
    align-items: center;
    gap: 15px;
    padding-bottom: 16px;
}
.what-date h6{
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #707081;
}
.what-cards-design p{
    font-weight: 400;
    font-size: 14px;
    margin: 0;
}
.ant-carousel{
    position:  relative !important;
    overflow: hidden;
}
.ant-carousel::after{
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 25%) !important;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
}
@primary-color: #4BB354;